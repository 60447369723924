.recharts-text, .recharts-tooltip-item-list {
  color: #9e9e9e;
  font-size: 0.75rem;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.375em;
}

.recharts-tooltip-label {
  color: black;
  font-size: 0.75rem;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.375em;
}

#root {
  height: 100%;
}

html, body {
  height: 100%;
}
